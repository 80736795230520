import React from 'react';
import {defaultTitle, defaultDescription} from '../config/config'
import {i18n} from '../utils'
import avatar from '../assets/images/perfil.jpeg'

export const Banner = () => {
    return (
    <section id="banner">
        <div className="container">
            <div className="cont-print">
                <h1 className="zoomIn animated">{i18n(defaultTitle, true, true)}</h1>
                <img src={avatar} className="avatar zoomIn animated"></img>
                <p className="zoomIn animated">{
                `${i18n(defaultDescription, true)}` 
                }</p>
            </div>
        </div>
    </section>
    );
};
