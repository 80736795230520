import React, { useState, useEffect } from 'react';
import {author} from '../config/config'
import { menu } from "../config/data";
import {i18n} from '../utils'
import AnchorLink from 'react-anchor-link-smooth-scroll';

export const Header = () => {
    const [scrolled, setScrolled ]= useState(false);
    
    useEffect(() => {
        window.addEventListener( 'scroll', handleScroll )
    })

    const handleScroll=() => {
        const offset = window.scrollY;
            if ( offset > 200 ) {
                setScrolled(true);
            } 
            else {setScrolled(false)};
    }
        
    let navFixed = '';
    if(scrolled) navFixed = 'nav-fixed'
    
    return (
        <header className={`${navFixed}`}>
            <div className="container-fluid">
                <div className="navbar-header">
                    <AnchorLink className="navbar-title" href="#banner">{i18n(author, true,  true)}</AnchorLink>
                </div>
                <nav>
                    <ul>
                        {
                            menu.map(({title, href})=> {
                                return <li key={title}><AnchorLink key={title} href={`#${href}`}>{i18n(title, true)}</AnchorLink></li>
                            })
                        }
                    </ul>
                </nav>
            </div>  
	    </header>
    );
};

