import React from 'react';
import UseProjects from '../../hooks/useProjects'
import {i18n, rowsItems} from '../../utils'

import { wrapper } from '../../config/data';

export const Project = () => {
    const { id, title, p} = wrapper.projects
    const useProjects = UseProjects()
    const rows =  rowsItems(useProjects)
    return (
        <section id={id} className="wrapper">
            <div className="container">
                <div className="row">
                    <header>
                        <h2>{`${i18n(title, true, true)}`}</h2>
                        <p>{`${i18n(p, true, true)}`}</p>
                    </header>
                </div>
            </div>
            <div className="content"> 
                <div className="container">
                    {
                        rows.map((row, i) => {
                            let grid =  12 / row.length  
                            return <div key={i} className="row" style={{margin: '1px auto'}}>
                            {row.map(({node}, j) => {
                                let {name, description, url} =  node
                                return <div  key={j} className={` card ${grid}u 12u$(small)`}>
                                    <a href={url} target="_blank" rel="noreferrer" >
                                        <div className="card-body">
                                                <h2>{i18n(name)}</h2>
                                                <p>{i18n(description)}</p>
                                        </div>
                                        <div className="card-footer">
                                            <div className="stats">
                                                <i className="fas fa-star"></i>
                                                <span>{node.stargazers.totalCount}</span>
                                                <i className="fas fa-code-branch"></i>
                                                <span>{node.forkCount}</span>
                                            </div>  
                                            <div className="languages">
                                                {
                                                    node.languages.nodes.map(({ id, name }) => (
                                                        <span key={id}>
                                                        {i18n(name)} 
                                                        </span>
                                                    ))
                                                }
                                            </div>  
                                        </div>
                                    </a>
                                </div>
                            })}
                            </div>
                        })
                    } 
                </div>
            </div>
        </section>
    );
};

