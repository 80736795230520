export function rowsItems(data) {
    let rows = [[], []], count  = 0
    data.forEach(( memo, i) => {
        if(i === 3 || i === 6) {
            count++  
            rows[count].push({...memo})
            return ;              
        } else {
            rows[count].push({...memo})
        }
    })
    return rows
}