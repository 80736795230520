module.exports = {
    menu: [ 
        {
            title: "sobre mi",
            href: "banner"
        },
        {
            title:"habilidades",
            href:"habilidades"
        },
        {
            title:"servicios",
            href:"servicios"
        },
        {
            title:"proyectos",
            href:"proyectos"
        },
        {
            title:"contacto", 
            href:"contacto", 
        }
    ], 
    wrapper: {
        skill: {
            title: "habilidades",
            id: "habilidades",
            p: "soy diseñador web profesional y desarrollador web",
            style: "",

            skills: [
                {
                    name:"reactJS",
                    icon: "react",
                    description:"react es una libreria para crear interfaces de usuarios utilizada por Facebook e Instagram. Muchas personas tienen a pensar en React como el componente V (La vista) en el módelo MVC."
                },
                {
                    name:"nodeJS",
                    icon: "node",
                    description:"Node.js es un entorno en tiempo de ejecución multiplataforma, de código abierto, para la capa del servidor basado en el lenguaje de programación ECMAScript"
                }, 
                {
                    name:"react native",
                    icon: "react",
                    description: "React Native le permite crear aplicaciones para dispositivos móviles utilizando sólo JavaScript. Utiliza el mismo diseño que React."
                },
            ]
        }, 
        service: {
            title: "servicios",
            id: "servicios",
            p: "lo que puedo hacer por usted",
            style: "",
            items: [
                {
                    name: "aplicaciones web",
                    icon:"desktop",
                    description:"desarrollamos soluciones a la medida de los objetivos de su marca; por eso queremos conocerte de manera personal, escuchar qué necesitas, qué metas tienes"
                }, 
                {
                    name: "sitios web",
                    icon:"globe",
                    description:"no trabajamos sobre plantillas. Sabemos que cada empresa es única. \n Por esto creamos diseños únicos, con portales inteligentes."
                },
                {
                    name: "aplicaciones moviles",
                    icon:"tablet",
                    description:"Desarrollamos aplicaciones (nativas, web-app, multiplataforma) bajo los últimos estándares de tecnología y usabilidad."
                }

            ]
        }, 
        projects: {
            title: "proyectos",
            id: "proyectos",
            p: "algunos de mis proyectos",
        }, 
        contact: {
            title: "contacto",
            id: "contacto",
            p: "Hablemos de su proyecto ",
        }
    }
}