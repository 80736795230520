import React from 'react';
import {i18n} from '../../utils'
import { wrapper } from '../../config/data';

export const About = () => {
    const { id, title, p, skills} = wrapper.skill
    return (
        <section id={id} className="wrapper">
            <div className="container">
                <div className="row">
                    <header>
                        <h2>{`${i18n(title, true, true)}`}</h2>
                        <p>{`${i18n(p, true, true)}`}</p>
                    </header>
                </div>
            </div>
            <div className="content"> 
                <div className="container">
                    <div className="row">
                        {
                            skills.map(({description, icon, name}, i) => {
                                return <div key={i} className="feature 4u 12u$(small)">
                                    <div className="diamond">
                                        <i className={`fab fa-${icon} fa-4x`}></i>
                                    </div>
                                    <h2>{i18n(name, true, true)}</h2>
                                    <p>{i18n(description, true)}</p>
                                </div> 
                            })
                        }
                    </div>
                </div>     
                
            </div>
        </section>
    );
};
