import React from 'react';
import { wrapper } from "../../config/data";
import {i18n, rowsItems} from '../../utils'

export const Services = () => {
    const { id, title, p, items} = wrapper.service
    let rows  = rowsItems(items)
    return (
        <section id={id} className="wrapper black">
            <div className="container">
                <div className="row">
                    <header>
                        <h2>{`${i18n(title, true, true)}`}</h2>
                        <p>{`${i18n(p, true, true)}`}</p>
                    </header>
                </div>
            </div>
            <div className="content"> 
                <div className="container">
                    {
                        rows.map((row, i) => {
                            let grid =  12 / row.length  
                            return <div key={i} className="row">
                            {row.map(({name, icon, description}, j) => {
                                return<div key={j} className={`feature  ${grid}u 12u$(small)`} >
                                <div className="box">
                                    <div className="diamond">
                                        <i className={`fa fa-${icon} fa-4x`}></i>
                                    </div>
                                    <h2>{i18n(name, true, true)}</h2>
                                    <p>{i18n(description)}</p>
                                </div>
                                
                            </div> 
                            })}
                            </div>
                        })
                    } 
                </div>     
                
            </div>
        </section>
    );
};
