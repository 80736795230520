import React from 'react';
import { wrapper } from "../../config/data";
import { socialLinks } from "../../config/config";
import {i18n} from '../../utils'


export const Contact = () => {
    const { id, title, p} = wrapper.contact
    return (
        <section id={id} className="wrapper black">
            <div className="container">
                <div className="row">
                    <div className="column 12u 12(small)">
                        <header>
                            <h2>{i18n(title, true, true)}</h2>
                            <p>{i18n(p, true, true)}</p>
                        </header>
                        <ul className="icons zoomIn animated"> 
                            <li><a target="_blank" rel="noreferrer" href={socialLinks.twitter} className="icon rounded "><i className="fab fa-telegram-plane"></i></a></li>
                            <li><a target="_blank" rel="noreferrer" href={socialLinks.twitter} className="icon rounded "><i className="fab fa-twitter"></i></a></li>
                            <li><a target="_blank" rel="noreferrer" href={socialLinks.linkedin} className="icon rounded"><i className="fab fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};
