import React from 'react';

export const Layout = ({children}) => {
    return (
        <div>
            {children}
        </div>
    );
};

