import * as React from "react"
import {Layout, SEO, Header, Banner, About, Services, Project, Contact} from '../components/';
import '../assets/scss/style.scss';


const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <Header />
      <Banner />
      <About />
      <Services />
      <Project />
      <Contact />
    </Layout>
  )
}

export default IndexPage
