import React from 'react';
import Helmet from "react-helmet";
import Miigangls from '../assets/images/icon.jpg' 
import { url,
    defaultDescription,
    social,
    defaultTitle,
    socialLinks,
} from "../config/config";

export const SEO = ({title = defaultTitle, decription =  defaultDescription, location = '' }) => {
    return ( <Helmet>
        <meta charset="UTF-8" />
        <title>{title}</title>
        <link rel="shortcut icon" type="image/x-icon" href="../images/cloud01.png" />
        <meta http-equiv="content-type" content="text/html; charset=utf-8" />
        <meta name="description" content={decription} />
        <meta name="image" content={Miigangls} />

        <meta property="og:url" content={`${url}${location}/?ref=miigangls.com`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={decription} />
        <meta property="og:image" content={Miigangls} />
        <meta property="fb:app_id" content={social.facebook} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content={socialLinks.twitter} />
        <meta name="twitter:site" content={social.twitter} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={decription} />
        <meta name="twitter:image:src" content={Miigangls} />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.5.0/css/all.css" integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU" crossOrigin="anonymous" />
    </Helmet>
    );
};

