module.exports = {
    defaultTitle: 'Miguel A. Mendoza C.' ,
    logo: '',
    author: 'miigangls',
    url: 'https://miigangls.com',
    legalName: 'Miguel A. Mendoza C.',
    defaultDescription: 'Soy Un Ingeniero Full Stack Con Desarrollo De Sitios Web. La Vida Es Un Proceso De Aprendizaje Continuo, Por Lo Que El Trabajo Me Hace Más Riguroso, El Viaje Hace Mi Vida Más Colorida. Puedo Hacer Muchos Amigos Cuando Codifico Y Viajo.'
    ,
    socialLinks: {
      telegram: 'http://www.twitter.com/miigangls',
      twitter: 'http://www.twitter.com/miigangls',
      facebook: 'http://www.facebook.com/miigangls',
      github: 'https://github.com/miigangls',
      linkedin: 'https://www.linkedin.com/in/miigangls',
      instagram: 'https://instagram.com/miigangls',
    },
    social: {
      facebook: 'miigangls',
      twitter: '@miigangls',
    },
    themeColor: '#6b63ff',
    backgroundColor: '#6b63ff'
  };
  