import {graphql, useStaticQuery} from 'gatsby'

export default function useProjects() {
    const {
        github: {
          viewer: {
            repositories: { edges },
          },
        },
      } = useStaticQuery(
        graphql`
          {
            github {
              viewer {
                repositories(first: 6, orderBy: {field: PUSHED_AT, direction: DESC}, privacy: PUBLIC, affiliations: OWNER) {
                  edges {
                    node {
                      id
                      name
                      url
                      description
                      stargazers {
                        totalCount
                      }
                      forkCount
                      languages(first: 3) {
                        nodes {
                          id
                          name
                        }
                      }
                      isDisabled
                      isArchived
                    }
                  }
                }
              }
            }
          }
        `
      );
  return edges
}